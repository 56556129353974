<template>
  <div class="manager-container" v-loading="showTemplate === 0">
    <edit-automatic-schedule
      v-if="showTemplate === 2"
      :job-data="currentContract"
      :mpaTransactionData="mpaTransactionData"
      :hasSysAdminPermission="hasSysAdminPermission"
      :locationOptionProps="locationOptionProps"
      :availableBargesOptions="availableBargesOptions"
      :availableBargesLoading="availableBargesLoading"
      @close="closeDetail"
      @checkAvailableBargesOptions="visibleChange"
      :sgTradexState.sync="sgTradexState"
      @onClearInterval="onClearInterval"
    >
    </edit-automatic-schedule>
    <handle-exwharf-schedule
      v-if="showTemplate === 'loading'"
      :job-data="currentContract"
      @close="closeDetail"
      :mpaTransactionData="mpaTransactionData"
      :currentCompanyShips="currentCompanyShips"
      @refresh-company-ships="getShipsByCompany"
      :locationOptionProps="locationOptionProps"
    ></handle-exwharf-schedule>
    <handle-delivered-schedule
      v-if="showTemplate === 'delivery'"
      :job-data="currentContract"
      :mpaTransactionData="mpaTransactionData"
      :hasSysAdminPermission="hasSysAdminPermission"
      :locationOptionProps="locationOptionProps"
      :sgTradexState.sync="sgTradexState"
      @close="closeDetail"
      @onClearInterval="onClearInterval"
    ></handle-delivered-schedule>
    <!-- <schedule-form
      v-if="showTemplate === 'delivery'"
      :job-data="currentContract"
      :locationOptionProps="locationOptionProps"
      :show-template="showTemplate"
      @close="closeDetail"
    ></schedule-form> -->
    <handle-internal-transfer
      v-if="showTemplate === 'sts'"
      :job-data="currentContract"
      :mpaTransactionData="mpaTransactionData"
      :hasSysAdminPermission="hasSysAdminPermission"
      :locationOptionProps="locationOptionProps"
      @close="closeDetail"
      :sgTradexState.sync="sgTradexState"
      @onClearInterval="onClearInterval"
      :currentCompanyShips="currentCompanyShips"
      @refresh-company-ships="getShipsByCompany"
    ></handle-internal-transfer>
    <template v-if="showTemplate === 1">
      <div class="flex-between-row">
        <h1 class="div-main-view-title">{{ orderType }}</h1>
      </div>
      <!-- type="flex"
      justify="space-between" -->
      <el-row
        :class="isFilterVisit ? ' shadow-border-bottom handler-bar' : 'handler-bar'"
        :gutter="25"

        align="middle"
      >
        <el-col :span="isMobileScreen ? 24 :12">
          <el-tabs v-model="activeName" @tab-click="getJobTableData()">
            <el-tab-pane
              v-for="(item, index) in statusTabs"
              :key="item.name"
              :label="item.name"
              :name="index.toString()"
            >
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="isMobileScreen ? 24 :12">
          <div class="button-block">
            <div class="query-item filter-btn">
              <span
                :class="!isFilterVisit ? 'span-btn-primay' : 'span-btn-active'"
                @click="isFilterVisit = !isFilterVisit"
              >
                Filter
              </span>
            </div>
            <div class="query-item search-bar">
              <el-input
                class="round-input"
                :size="isMobileScreen ? 'mini' : 'default'"
                placeholder="Search By Barge, Vessel or Terminal"
                v-model="jobSearchVal"
                @keypress.enter.native="getJobTableData()"
              >
                <el-button
                  type="primary"
                  slot="append"
                  icon="el-icon-search"
                  @click="getJobTableData()"
                ></el-button>
              </el-input>
            </div>
            <div class="query-item create-btn">
              <el-button
                :size="isMobileScreen ? 'mini' : 'default'"
                v-if="hasPermission($permissionFunctionCode.ADD)"
                round
                type="primary"
                @click="createNewSchedule"
                :loading="createNewScheduleLoading"
              >
              <span v-if="isMobileScreen">+</span>
              <span v-else>{{ $t("Create Job")}}</span>


              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="isFilterVisit"
        class="handler-bar"
        style="margin: 5px 0"
        :gutter="5"
        align="middle"
      >
        <el-col :span="20" v-bind:class="{ 'filter-line-mobile': isMobileScreen, 'filter-line': !isMobileScreen}">
          <template><span class="label-mobile" v-if="isMobileScreen">Job Type:</span>
          <el-select  :size="isMobileScreen ? 'mini' : 'small'" v-model="jobFilter.type" placeholder="Select a Job Type" @change="getJobTableData()">
            <el-option
              v-for="item in jobOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template><span class="label-mobile" v-if="isMobileScreen">Contract Type:</span>
          <el-select  :size="isMobileScreen ? 'mini' : 'small'"  v-model="jobFilter.termContractType" placeholder="Select a Contract Type" @change="getJobTableData()">
            <el-option
              v-for="item in termContractTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template><span class="label-mobile" v-if="isMobileScreen">Ship:</span>
          <el-select  :size="isMobileScreen ? 'mini' : 'small'"  class="vessel-selector"  v-model="jobFilter.bargeShipId" placeholder="Select a Ship" @change="handleSelectFilterShip">
            <el-option
              v-for="item in shipOptsByCompany"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template><span class="label-mobile" v-if="isMobileScreen">Company:</span>
          <el-select  :size="isMobileScreen ? 'mini' : 'small'"  class="vessel-selector"  v-model="jobFilter.companyId" placeholder="Select a Company" @change="getJobTableData()">
            <el-option-group
              v-for="group in companyFiltersOptions"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-option-group>
          </el-select>
        </template>
        <template><span class="label-mobile" v-if="isMobileScreen">Date Range:</span>
          <span>
            <el-date-picker
              :size="isMobileScreen ? 'mini' : 'small'"
              v-model="jobFilter.date"
              style="width: 100%"
              type="datetimerange"
              range-separator="to"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              @change="getJobTableData()"
              popper-class="custom-datetimerange"
            >
            </el-date-picker>
          </span>
          </template>
        </el-col>
        <el-col :span="4">
          <span
            style="float: right; width: 50px"
            class="span-btn-primay"
            @click="handleResetSearchQuery()"
          >
            Reset
          </span>
        </el-col>
      </el-row>
      <div
        class="table-block margin-top-0"
        :style="{ height: isFilterVisit ? 'calc(100% - 150px)' : 'calc(100% - 74px)' }"
      >
        <table-job
          hasTableMenu
          hasPagination
          :isJobTableLoading="isJobTableLoading"
          :availableBargesLoading="availableBargesLoading"
          :availableBargesOptions="availableBargesOptions"
          :jobTableData="jobTableData"
          :activeTab="+activeName"
          :jobPagination="jobPagination"
          @handleJobDropdownMenuClick="handleJobDropdownMenuClick"
          @handleJobPageChange="handleJobPageChange"
          @handleJobPageSizeChange="handleJobPageSizeChange"
          @checkAvailableBargesOptions="visibleChange"
          @handleJobTableRefresh="handleJobTableRefresh"
        />
      </div>
    </template>
    <select-schedule-type
      v-if="contractTypeVisible"
      @closed="contractTypeVisible = false"
      @selected="selectedHandler"
    >
    </select-schedule-type>
    <!--  for locations  -->
    <add-terminal-and-berth
      v-if="locationDialogVisible"
      @closed="locationDialogVisible = false"
    >
    </add-terminal-and-berth>
    <file-preview-list :visible.sync="attachmentsVisible" :order="currentContract">
    </file-preview-list>
    <dialog-blockchain-info
      @onClose="handleCloseBlockchainInfo"
      v-if="blockchainInfoVisible"
      :propsData="blockchainData"
    />
    <dialog-transfer-job
      :dialogTransferJob="dialogTransferJob"
      :job-data="currentContract"
      :availableBargesLoading="availableBargesLoading"
      :availableBargesOptions="availableBargesOptions"
      @checkAvailableBargesOptions="visibleChange"
      @handleTransferJobsDialogClose="dialogTransferJob = false"
      @handleJobTableRefresh="handleJobTableRefresh"
    />
    <AddRecipientEmail
      v-if="addRecipientmailFormDialogVisible"
      :entity="currentContract"
      @close="() => addRecipientmailFormDialogVisible = false"
    />
  </div>
</template>

<script>
import baseMixin from "@/mixins/base-mixin";
import baseTableMixin from "@/mixins/base-table-mixin";
import SelectScheduleType from "./components/SelectScheduleType";
import EditAutomaticSchedule from "./components/EditAutomaticSchedule";
import FilePreviewList from "@/components/FilePreviewList";
import HandleExwharfSchedule from "./components/HandleExwharfSchedule";
import HandleDeliveredSchedule from "./components/HandleDeliveredSchedule";
import HandleInternalTransfer from "./components/HandleInternalTransfer";
import {
  TERM_CONTRACT_TYPE,
  ORDER_TYPE,
  HEADER_CELL_STYLE,
  CELL_STYLE,
} from "@/constants.js";
import {
  getStatusTagStyle,
  sortByAlphabetical,
  capitalizeFirstLetter,
  formatUtcTimeString,
  checkIsNotNullOrEmpty,
} from "@/utils";
import { mapState, mapActions } from "vuex";
/* for locations */
import AddTerminalAndBerth from "./components/AddTerminalAndBerth.vue";
import DialogBlockchainInfo from "@/components/DialogBlockchainInfo";
import TableJob from "./components/TableJob.vue";
import { getAvailableBarges } from "@/services/modules/ops.js";
import DialogTransferJob from "./components/DialogTransferJob.vue";
import AddRecipientEmail from "./components/AddRecipientEmail.vue";
import { getCompanyFilters } from "@/services/modules";

const JOB_TYPES = {
  1: "Delivery",
  2: "Loading",
  3: "STS",
};

const STATUS_MAPPING = {
  1: {
    name: "Pending",
    color: "#1F77FB",
  },
  2: {
    name: "Confirmed",
    color: "#08E204",
  },
  3: {
    name: "In progress",
    color: "#FFB800",
  },
  4: {
    name: "Completed",
    color: "#BA63FF",
  },
  5: {
    name: "Cancelled",
    color: "#F60000",
  },
};

const TYPE_MAPPING = {
  1: "delivery",
  2: "loading",
  3: "sts",
};

export default {
  name: "nomination",
  mixins: [baseMixin, baseTableMixin],
  components: {
    // LinkOrderDialog,
    EditAutomaticSchedule,
    FilePreviewList,
    SelectScheduleType,
    HandleExwharfSchedule,
    HandleDeliveredSchedule,
    HandleInternalTransfer,
    /* for locations */
    AddTerminalAndBerth,
    DialogBlockchainInfo,
    TableJob,
    DialogTransferJob,
    AddRecipientEmail,
  },
  inject: ["reload"],
  data() {
    const jobPagination = {
      pageSize: 20,
      pageNumber: 0,
      total: 0,
    };
    const jobFilter = {
      date: [],
      type: null,
      termContractType: null,
      bargeImo: null,
      bargeShipId: null,
      companyId: null,
    };
    return {
      jobFilter,
      jobSearchVal: "",
      cascaderKey: 0,
      TERM_CONTRACT_TYPE,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      data: {
        curPage: 1,
        limit: 20,
        payTime1: null, // 收入日期1
        payTime2: null, // 收入日期2
      },
      // value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      scheduleType: 1,
      JOB_TYPES,
      STATUS_MAPPING,
      queryCondition: {},
      activeName: "0", // follow tab index
      linkOrderVisible: false,
      contractTypeVisible: false,
      createNewScheduleLoading: false,
      showTemplate: 0, // 0：空白加载； 1：列表； 2：自动生成的schedule； 'loading'：HandleExwharfSchedule； 'delivery'：HandleDeliveredSchedule； 'sts'：HandleInternalTransfer
      contractType: "",
      queryUrl: "",
      initialLinkData: null,
      attachmentsVisible: false,
      // 当前合同
      currentContract: {},
      mpaTransactionData: [],
      orderType: "",
      getNewTable: false,
      countFlag: 0,
      /* for locations */
      locationDialogVisible: false,
      systemAdminRole: false,
      locationOptionProps: {
        value: "name",
        label: "name",
        children: "children",
      },
      currentCompanyShips: [],
      availableBargesOptions: [],
      availableBargesLoading: true,
      isFilterVisit: false,
      blockchainData: null,
      blockchainInfoVisible: false,
      jobPagination,
      isJobTableLoading: false,
      dialogTransferJob: false,
      sgTradexState: {
        isSgTradexInfoVisible: false,
        sgTradexTransactionLoading: false,
        sgTradexTransactionData: [],
        actions: {},
      },
      addRecipientmailFormDialogVisible: false,
      companyFiltersOptions: [],
      buyerSellerList: [],
    };
  },
  computed: {
    ...mapState({
      currentCompany: "currentCompany",
      currentCompanySettings: "currentCompanySettings",
      user: "user",
      roles: "roles",
      locationList: "locationList",
      companyList: "companyList",
      permissions: "permissions",
      isMobileScreen: "isMobileScreen",
    }),

    hasSysAdminPermission() {
      return this.permissions.hasSysAdminPermission;
    },
    statusTabs() {
      return [
        {
          name: "All Schedule",
          status: "1,2,3",
        },
        {
          name: "Pending",
          status: "1",
        },
        {
          name: "Confirmed",
          status: "2",
        },
        {
          name: "In Progress",
          status: "3",
        },
        {
          name: "Completed",
          status: "4",
        },
        {
          name: "Cancelled",
          status: "5",
        },
      ];
    },
    liftingDetail() {
      const mapping = {
        "Ex-Wharf": "exwharfLiftingDetail",
        Delivered: "deliveredLiftingDetail",
      };
      return mapping[this.orderType];
    },
    shipOptsByCompany() {
      return [
        { value: "", label: "All Schedule" },
        ...this.currentCompanyShips.map((item) => ({
          value: item?.id,
          label: item?.shipName,
          callSign: item?.callSign,
          tenantId: item?.tenantId,
          shipName: item?.shipName,
          imo: item?.imo,
        })),
      ];
    },
    jobOpts() {
      const jobTypes = Object.keys(JOB_TYPES).map((key) => ({
        value: key,
        label: JOB_TYPES[key],
      }));
      return [
        {
          value: "",
          label: "All Job Type",
        },
        ...jobTypes,
      ];
    },
    termContractTypeOption() {
      const termTypes = Object.keys(TERM_CONTRACT_TYPE).map((key) => ({
        value: TERM_CONTRACT_TYPE[key],
        label: this.capitalizeFirstLetter(TERM_CONTRACT_TYPE[key], "_"),
      }));
      return [
        {
          value: "",
          label: "All Term Contract Type",
        },
        ...termTypes,
      ];
    },
  },
  async created() {
    await this.getCompanyFiltersOptions()
    this.queryUrl = this.$apis.queryContract;
    this.deleteUrl = this.$apis.deleteContractById;
    this.orderType = this.$route?.name;
    this.getShipsByCompany();
    this.getJobTableData();
    this.systemAdminRole = !!this.roles.find(
      (item) => item.code === this.$roleCode.systemAdmin
    );
    this.$store.dispatch("getMpaFuelTypeList");
  },
  watch: {
    locationList() {
      this.cascaderKey++;
    },
    isFilterVisit() {
      this.styleDivTable();
    },
    "currentCompany.id": {
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.getJobTableData();
          }, 1000);
        }
      },
      immediate: false,
    },
  },
  methods: {
    getStatusTagStyle,
    capitalizeFirstLetter,
    formatUtcTimeString,
    checkIsNotNullOrEmpty,
    handleSelectFilterShip(val) {
      const selectShips = this.shipOptsByCompany.filter((ship) => ship.value === val);
      this.jobFilter.bargeImo = selectShips != [] ? selectShips[0]?.imo : null;
      this.jobFilter.bargeShipId = selectShips != [] ? selectShips[0]?.value : null;
      this.getJobTableData();
    },
    getJobTableData(
      pageSize = this.jobPagination.pageSize,
      pageNumber = 0,
      query = null
    ) {
      this.isJobTableLoading = true;
      let filter = null;
      filter = `${
        checkIsNotNullOrEmpty(this.jobFilter.type) ? `type:${this.jobFilter.type}` : ""
      }`;
      filter += `${
        checkIsNotNullOrEmpty(this.jobFilter.termContractType)
          ? `${filter ? "and" : ""} termContractType:'${this.jobFilter.termContractType}'`
          : ""
      }`;
      const imoFiler = checkIsNotNullOrEmpty(this.jobFilter.bargeImo)
        ? `bargeImo:${this.jobFilter.bargeImo}`
        : "";
      const shipIdFiler = checkIsNotNullOrEmpty(this.jobFilter.bargeShipId)
        ? `bargeShipId:${this.jobFilter.bargeShipId}`
        : "";
      const shipFiler =
        imoFiler +
        (checkIsNotNullOrEmpty(imoFiler) && checkIsNotNullOrEmpty(shipIdFiler)
          ? " or "
          : "") +
        shipIdFiler;
      filter += `${
        this.jobFilter?.date?.length > 0
          ? `${
              filter ? "and" : ""
            } stemStartDate>:'${this.jobFilter.date[0].toISOString()}' and stemEndDate<:'${this.jobFilter.date[1].toISOString()}'`
          : ""
      }`;

      filter += checkIsNotNullOrEmpty(imoFiler) ?   filter ? `and(${shipFiler}) `: `(${shipFiler}) ` : "";
      filter += `${filter ? "and" : ""} ${
        +this.activeName === 0 ? "status in (1, 2, 3)" : `status:${this.activeName}`
      }`;
      filter += checkIsNotNullOrEmpty(this.jobFilter.companyId) ?   filter ? `and ${this.jobFilter.companyId} `: `${this.jobFilter.companyId}` : "";

      filter = `&filter=${filter}`;
      if (this.jobSearchVal) {
        filter += `and (vesselName ~'*${this.jobSearchVal}*' or bargeName ~'*${this.jobSearchVal}*' or locationLevel1 ~'*${this.jobSearchVal}*' or floaterName ~'*${this.jobSearchVal}*')`;
      }
      const isCompletedTabview = this.activeName == 4;
      this.$request
        .get({
          url: `/ebdn/jobs/all?pageSize=${pageSize}&pageNumber=${pageNumber}&showLinkOrderJobs=true${
            filter ?? ""
          }${
            +this.activeName === 4 || +this.activeName === 5 ? "&sort=updatedAt desc" : ""
          }${isCompletedTabview ? "&showMpaTransactionStatus=true" : ""}`,
        })
        .then((res) => {
          if (res?.code === 1000) {
            this.jobTableData = res.data.content.map((item) =>
              Object.assign({}, item, {
                datetime:
                  item.type === 2
                    ? item.loadingTime
                      ? item.loadingTime
                      : ""
                    : item.vesselEta
                    ? item.vesselEta
                    : "",
                locationLevel: [item.locationLevel1, item.locationLevel2],
                terminal: item.type === 2 ? item.locationLevel1 : "",
                quantity:
                  (item.type === 2 ? item.loadingQty : item.finalQty) +
                  item.qtyUnitMetric,
                typeDisplay: this.generateJobTypeDisplay(item),
                orderDateDisplay: `${
                  item.stemStartDate
                    ? this.$moment(item.stemStartDate).format("YYYY-MM-DD")
                    : ""
                }\n${
                  item.stemEndDate
                    ? this.$moment(item.stemEndDate).format("YYYY-MM-DD")
                    : ""
                }`,
                buyerSysOrganizationName: this.findBuyerSellerCompanyName(item.buyerSysOrganizationId),
                sellerSysOrganizationName: this.findBuyerSellerCompanyName(item.sellerSysOrganizationId),
              })
            );
            this.jobPagination.total = res.data.totalElements;
            this.jobPagination.pageNumber = res.data.pageable.pageNumber + 1;
            this.showTemplate = 1;
          }
        })
        .finally(() => {
          this.styleDivTable();
          this.isJobTableLoading = false;
        });
    },
    handleResetSearchQuery() {
      this.resetJobFilter();
      this.getJobTableData();
    },
    resetJobFilter() {
      this.jobFilter = {
        date: [],
        type: null,
        termContractType: null,
        bargeImo: null,
        bargeShipId: null,
        companyId: null,
      };
    },
    /**
     * 监听创建时选择的类型
     */
    styleDivTable() {
      if (document.getElementsByClassName("div-main-list-page-table")[0]) {
        if (this.isFilterVisit) {
          document.getElementsByClassName("div-main-list-page-table")[0].style.height =
            "calc(100% - 54px)";
        } else {
          document.getElementsByClassName("div-main-list-page-table")[0].style.height =
            "calc(100% - 32px)";
        }
      }
    },
    visibleChange(isVisit, row) {
      if (isVisit) {
        this.availableBargesOptions = [];

        const {
          termContractId,
          termContractType,
          stemStartDate,
          stemEndDate,
          mitigatingCompanyOrgId,
          type,
        } = row;
        /*
          1: 'Delivery', 2: 'Loading', 3: 'STS'
        */
        if ([2, 3].includes(type)) {
          this.availableBargesOptions = this.currentCompanyShips;
          this.availableBargesLoading = false;
        } else if (
          [TERM_CONTRACT_TYPE.TC, TERM_CONTRACT_TYPE.COA].includes(termContractType) &&
          termContractId
        ) {
          this.reqCheckAvailableBargesOptions({
            termContractId,
            startDate: stemStartDate,
            endDate: stemEndDate,
          });
        } else {
          this.reqCheckAvailableBargesOptions({
            operatorSysOrganizationId: mitigatingCompanyOrgId,
            startDate: stemStartDate,
            endDate: stemEndDate,
          });
        }
      }
    },
    reqCheckAvailableBargesOptions(parmas) {
      getAvailableBarges(parmas)
        .then((res) => {
          this.availableBargesLoading = false;
          if (res?.code === 1000) {
            this.availableBargesOptions = sortByAlphabetical(
              res.data || [],
              "shipName"
            ).filter(
              (ship) =>
                !ship.charterId ||
                (ship.charterId && ship.charterId === this.currentCompany.id)
            );
          } else {
            this.availableBargesOptions = [];
            this.openErrMsg(res.message);
          }
        }).catch((err) => {

          this.availableBargesLoading = false;
          this.openErrMsg(err);
           this.availableBargesOptions = [];
        });
    },
    selectedHandler(scheduleType) {
      this.currentContract = {};
      this.scheduleType = scheduleType;
      this.showTemplate = TYPE_MAPPING[scheduleType];
    },

    /**
     * 创建合同
     */
    async createNewSchedule() {
      this.createNewScheduleLoading = true;
      this.currentContract = {};
      this.contractTypeVisible = true;
      this.createNewScheduleLoading = false;
    },
    /**
     * 编辑合同
     */
    editContract(contract) {
      this.currentContract = contract;
      if (contract?.status === 4 && contract?.id) {
        this.getMpaTransactionData(contract.id);
      }
      this.onHandleSgTradexTransaction(contract);
      // 存在orderId是自动生成的schedule，否则是手动创建的schedule
      if (contract.orderId) {
        this.showTemplate = 2;
      } else {
        this.showTemplate = TYPE_MAPPING[contract.type];
      }
    },
    getMpaTransactionData(jobId) {
      this.$request
        .get({
          url: `ebdn/jobs/${jobId}/mpa_transactions`,
        })
        .then((res) => {
          if (res?.code === 1000) {
            this.mpaTransactionData = res.data;
          }
        });
    },
    /* Handle Sg Tradex Transaction */
    onHandleSgTradexTransaction(contract) {
      if (contract?.status === 4 && contract?.type !== 2 && contract?.id) {
        this.sgTradexState.sgTradexTransactionData = [];
        this.sgTradexState.actions = {
          label: "Push To SgTradex",
          onAction: (jobId) => {
            this.handlePushToSgTradex(jobId);
          },
        };
        // check job status, type and current role
        let mitigatingCompanyOrgId = contract.mitigatingCompanyOrgId;
        if (
          !this.systemAdminRole &&
          contract.mitigatingCompanyOrgId !== this.currentCompany.id
        ) {
          mitigatingCompanyOrgId = false;
          this.sgTradexState.isSgTradexInfoVisible = false;
        }
        if (mitigatingCompanyOrgId) {
          this.$store
            .dispatch("getCompanySettings", mitigatingCompanyOrgId)
            .then((res) => {
              if (res?.code === 1000) {
                this.populateSgTradexTransactionInfo(contract.id);
              }
            });
        }
      }
    },
    populateSgTradexTransactionInfo(jobId) {
      this.sgTradexState.sgTradexTransactionLoading = true;
      this.getSgTradexTransactionData(jobId).then((res) => {
        if (this.checkHasVailidSgTradexSettings()) {
          this.checkCanPushToSgTradex(jobId);
        } else {
          this.sgTradexState.actions.label = false; // hide button,can not push
        }
        this.sgTradexState.isSgTradexInfoVisible = this.onCheckSgTradexIsVisibale();
      });
    },

    getSgTradexTransactionData(jobId) {
      return new Promise((resolve, reject) => {
        this.$request
          .get({
            url: `ebdn/jobs/${jobId}/sgtradex_b2b_transactions`,
          })
          .then((res) => {
            let result = 0;
            if (res?.code === 1000) {
              if (res.data.length > 0) {
                const idx = res.data.findIndex((item) => item.isSuccessful);
                if (idx === -1) {
                  // have not pushed Successfully
                  result = 1;
                } else {
                  // have pushed Successfully
                  result = 2;
                }
                this.sgTradexState.sgTradexTransactionData = res.data;
              }
            }
            resolve(result);
          })
          .finally(() => {
            this.sgTradexState.sgTradexTransactionLoading = false;
          });
      });
    },
    onCheckSgTradexIsVisibale() {
      if (this.systemAdminRole) {
        return (
          this.checkHasVailidSgTradexSettings() ||
          this.sgTradexState.sgTradexTransactionData.length > 0
        );
      } else {
        return !!(
          (this.checkHasVailidSgTradexSettings() ||
            this.sgTradexState.sgTradexTransactionData.length > 0) &&
          this.currentCompany?.id === this.currentContract?.mitigatingCompanyOrgId
        );
      }
    },
    checkHasVailidSgTradexSettings() {
      return !!(
        this.currentCompanySettings?.sgTradexParticipantId &&
        this.currentCompanySettings?.sgTradexPushAfter
      );
    },
    handlePushToSgTradex() {
      this.sgTradexState.sgTradexTransactionLoading = true;
      const url = `${this.$apis.createNewJob}/${this.currentContract.id}/push_to_sgtradex`;
      this.$request
        .post({
          url,
        })
        .then((res) => {
          if (res?.code === 1000) {
            this.sgTradexState.actions.label = false; // Push successfuly
            this.$message.success(`${this.$t("sgTradexPushEbdnSuccess")}`);
          }
        })
        .catch((e) => {})
        .finally(() => {
          this.populateSgTradexTransactionInfo(this.currentContract.id);
          this.sgTradexState.sgTradexTransactionLoading = false;
        });
    },
    checkCanPushToSgTradex(jobId) {
      const canPushToSgTradexApiCall = (interval) => {
        this.checkCanPushToSgTradexInterval = interval;
        this.$request
          .post({
            url: `${this.$apis.createNewJob}/${jobId}/can_push_to_sgtradex`,
          })
          .then((res) => {
            if (res?.code === 1000) {
              if (res?.data != null) {
                if (interval) {
                  clearInterval(interval);
                }
                this.sgTradexState.actions.label = res?.data ? "Push To SgTradex" : false;

                this.sgTradexState.sgTradexTransactionLoading = false;
                this.sgTradexState.actions.disabled = false;
              } else {
                this.sgTradexState.sgTradexTransactionLoading = true;
                // res.data = null means  pushing now. disabled btn
                this.sgTradexState.actions.disabled = true;
              }
            } else {
              if (interval) {
                clearInterval(interval);
              }
            }
          })
          .finally();
      };
      this.sgTradexState.sgTradexTransactionLoading = true;
      const interval = setInterval(() => {
        canPushToSgTradexApiCall(interval);
      }, 5000);
      canPushToSgTradexApiCall(interval);
    },
    onClearInterval() {
      if (this.checkCanPushToSgTradexInterval) {
        clearInterval(this.checkCanPushToSgTradexInterval);
      }
    },

    /*
     * 关闭详情
     */
    closeDetail(noAction) {
      if (!noAction) {
        this.resetJobFilter();
        this.isFilterVisit = false;
        this.jobSearchVal = "";
        this.activeName = 0;
        this.jobPagination = {
          pageSize: 20,
          pageNumber: 0,
          total: 0,
        };
      }
      this.showTemplate = 0;
      this.getJobTableData();
    },

    /*
     * 打开附件列表
     */
    openAttachments(row) {
      this.currentContract = row;
      this.attachmentsVisible = true;
    },
    handleCloseBlockchainInfo() {
      this.blockchainInfoVisible = false;
    },
    handleJobDropdownMenuClick(action, data) {
      switch (action) {
        case "view":
          this.editContract(data);
          break;
        case "viewBlockchainInfo":
          this.blockchainData = {
            moduleApi: "/ebdn/jobs",
            id: data.id,
          };
          this.blockchainInfoVisible = true;
          break;
        case "transferJob":
          this.currentContract = data;
          this.dialogTransferJob = true;
          break;
        case "addRecipientEmail":
        case "shareEBDN":
          this.currentContract = data;
          this.addRecipientmailFormDialogVisible = true;
          break;
        default:
          break;
      }
    },
    generateJobTypeDisplay(job) {
      const withOrder = job.orderId !== null && job.orderId !== undefined;
      const ownedJob =
        this.currentCompany !== null &&
        this.currentCompany !== undefined &&
        (this.currentCompany.id === job.mitigatingCompanyOrgId ||
          this.currentCompany.id === job.sellerSysOrganizationId ||
          this.currentCompany.id === job.buyerSysOrganizationId);

      if (withOrder && ownedJob) {
        if (job.orderType === ORDER_TYPE.BARGING_NOMINATION) {
          if (job.termContractType) {
            return job.termContractType === TERM_CONTRACT_TYPE.COA
              ? `${this.JOB_TYPES[job.type]} - B (COA)`
              : `${this.JOB_TYPES[job.type]} - B (TC)`;
          } else {
            return `${this.JOB_TYPES[job.type]} - B`;
          }
        } else {
          if (job.termContractType) {
            return `${this.JOB_TYPES[job.type]} - Term`;
          } else {
            return this.JOB_TYPES[job.type];
          }
        }
      }

      return this.JOB_TYPES[job.type];
    },
    handleJobPageChange(pageNumber) {
      this.getJobTableData(this.jobPagination.pageSize, pageNumber - 1);
    },
    handleJobPageSizeChange(pageSize) {
      this.getJobTableData(pageSize, 0);
    },
    handleJobTableRefresh() {
      this.getJobTableData(this.jobPagination.pageSize, 0);
    },
    async getShipsByCompany() {
      try {
        const res = await this.$request.get({
          url: `${this.$apis.shipBaseUrl}?pageNumber=1&pageSize=999999&organizationId=${this.currentCompany.id}`,
        });

        if (res?.code === 1000) {
          const ships = sortByAlphabetical(res.data.records || [], "shipName").filter(
            (ship) =>
              !ship.charterId ||
              (ship.charterId && ship.charterId === this.currentCompany.id)
          );
          this.currentCompanyShips = ships;

          this.availableBargesOptions = ships;
        }
      } catch (e) {
        this.$message.error("Error retrieving list of barges");
      }
    },
    async getCompanyFiltersOptions() {
      await getCompanyFilters().then(res => {
        if( res.bargeOperators ?? false ) {
          const bargeOptions = []
          res.bargeOperators.map(item => {
            bargeOptions.push({
              value: `bargeOwnerId:'${item.id}'`,
              label: item.name,
            })
          })
          this.companyFiltersOptions.push({
            label: 'Barge Owner Company',
            options: bargeOptions,
          })
        }

        if( res.bdnLicenseCompanies ?? false ) {
          const bdnBargeOptions = []
          res.bdnLicenseCompanies.map(item => {
            bdnBargeOptions.push({
              value: `bargeOrgId:'${item.id}'`,
              label: item.name,
            })
          })
          this.companyFiltersOptions.push({
            label: 'BDN License Company',
            options: bdnBargeOptions,
          })
        }

        if( res.customers ?? false ) {
          const customersOptions = []
          this.buyerSellerList = res.customers
          res.customers.map(item => {
            customersOptions.push({
              value: `(buyerSysOrganizationId:'${item.id}' or sellerSysOrganizationId:'${item.id}')`,
              label: item.name,
            })
          })
          this.companyFiltersOptions.push({
            label: 'Customer/Seller Company',
            options: customersOptions,
          })
        }

      })
    },
    findBuyerSellerCompanyName(id) {
      const item = this.buyerSellerList.find(item => item.id == id)
      return item?.name ?? null
    }
  },
};
</script>

<style lang="scss" scoped>
.manager-container {
  height: 100%;
  width: 100%;

  /deep/ .el-loading-spinner i {
    font-size: 30px;
  }

  .handler-bar {
    /deep/ .el-tabs__nav-wrap::after {
      background-color: transparent;
    }

    /deep/ .el-input__inner {
      border-color: #eaecef;
    }

    /deep/ .el-tabs__nav-next,
    /deep/ .el-tabs__nav-prev {
      font-size: 12px;
      // color: #0f376a;
    }
  }

  .button-block {
    @include flex-center-row;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;

    .query-item {
      display: inline-flex;
      align-items: center;
      margin: 10px 0 10px 15px;
    }

    .button-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

}

.button-popover {
  @include flex-center-row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;

  .button-item {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    color: #4a4d54e6;
    padding: 0 12px;

    i {
      font-size: 21px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 0 0px 0;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.more-button {
  font-size: 18px;
  padding: 5px 0;
}
.filter-line {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 23% 23% 23% 30%;
}
.filter-line-mobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  /deep/ .el-input__inner{
    width: 220px !important;
  }

  .label-mobile{
    font-size: 12px;
    color:#4C565C;
    font-weight: 400;
    display: inline-block;
    height: 100%;
    width: 80px;
    // line-height: 20px;
    margin: auto 5px;


  }
  /deep/.el-select{
      margin-bottom: 5px !important;
    }


}
/deep/ .custom-datetimerange{
  // /deep/ .el-picker-panel__body{
    display: flex !important;
    flex-direction: column;
  // }
  /deep/ .el-date-range-picker__time-header {
    display: flex !important;
    flex-direction: column;
  }
}
.div-main-list-page-table {
  height: calc(100% - 32px);
  text-align: right;
}
@media screen and (max-width: 599px){
  .button-block{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between!important;
    .filter-btn{
      margin-left: 0px !important;
      .span-btn-primay{
        margin-left:0px !important;
      }
    }
    .create-btn{
      .el-button{
        padding:5px 8px !important;
        span{
        font-size: 20px;
      }
      }
    }
  }
}
</style>
