<template>
  <div class="manager-container  module-main-body" v-loading="fullScreenLoading&&isPricingTierEnabled">
    <el-row class="module-content-top-bar">
      <el-col :sm="8" :md="10" :lg="10">
        <h1 class="top-bar-title">Set Customer Tier</h1>
      </el-col>
      <!--el-col :sm="16" :md="14" :lg="14">
        <div class="button-bar flex-end">
          <div class="button-block" style="padding-top:5px">
            <el-button class="primary-button" @click="() => {}">{{ this.$t('save') }}</el-button>
          </div>
        </div>
      </el-col-->
    </el-row>
    <el-row type="flex" class="div-main-body-left-right module-content-main-view">
      <el-col :span="24" style="padding: 20px;">
        <div style="height: 100%; overflow-y: auto;">
          <div class="flex-end" style="padding-bottom: 10px;">
            <!-- <div><b>Customers</b></div> -->
            <div class="query-item search-bar flex-end" >
              <el-input class="round-input" placeholder="Search Company" v-model="searchCustomer">
                <el-button type="primary" slot="append" icon="el-icon-search" @click="searchCustomerHandler"></el-button>
              </el-input>
            </div>
          </div>
          <el-table v-if="isPricingTierEnabled" :data="data" :border="true" :header-cell-style="HEADER_CELL_STYLE" :cell-style="CELL_STYLE"
          >
            <el-table-column label="Company Name" :min-width="240">
              <template #default="scope">{{ scope.row.companyName }}</template>
            </el-table-column>

            <el-table-column label="Company Code (SAGE300)" :min-width="200">
              <template #default="scope">
                <el-select v-model="scope.row.victoryCompanyCode" filterable allow-create  placeholder="Choose or input"
                  @visible-change="getVictoryCompanyCodeList($event,scope.row.organizationId)"
                  @change="handleUpdateCustomerPricingTier(scope.row)">
                  <el-option
                    v-for="item in victoryCompanyCodeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Credit Limit" :min-width="180">
              <template #default="scope">
                 <!-- :precision="0" -->
                <el-input-number
                  type="number"
                  @change="handleUpdateCustomerPricingTier(scope.row)"
                  v-model="scope.row.creditLimit"
                  :controls="false"
                  :min="0"
                ><div slot="append">USD</div>
                </el-input-number>
              </template>
            </el-table-column>

            <el-table-column label="Tier" :min-width="150">
              <template #default="scope">
                <el-select v-model="scope.row.tierId" @change="handleUpdateCustomerPricingTier(scope.row)">
                  <el-option v-for="tier in tiers" :key="tier.tierId" :label="tier.title" :value="tier.tierId"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Last Updated" :min-width="150">
              <template #default="scope">{{ formatDateTimeMixin(scope.row.updatedTime) }}</template>
            </el-table-column>
            <el-table-column label="Updated By" :min-width="150">
              <template #default="scope">{{ scope.row.updatedBy }}</template>
            </el-table-column>
          </el-table>
          <div v-else >The Pricing Tier Is Not Activated</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
import { getVictoryCompanyCodeListReq, getVictoryCompanyCodeListReqNoFilter } from '@/services/modules'

export default {
  name: "setCustomerTier",
  mixins: [baseMixin],
  computed: {
    ...mapState(['currentCompany']),
  },
  data() {
    return {
      HEADER_CELL_STYLE, CELL_STYLE,
      fullScreenLoading: false,
      searchCustomer: '',
      customerTierData: [],
      data: [],
      tiers: [],
      isPricingTierEnabled: false,
      victoryCompanyCodeList:[]
    }
  },
  async created() {
    await this.getData()
  },
  methods:{
    async getData() {
      this.fullScreenLoading = true
      await this.getPricingTierDefinition()
      await this.getCustomerPricingTier()
      this.fullScreenLoading = false
    },
    async getPricingTierDefinition() {
      await this.pricingTierDefinition().then( res => {
        if( res ) {
         const tiers=[];
          res.map( item => {
            tiers.push({ tierId : item.id, title : item.name })
          })
          this.tiers = tiers;
        }
      })
    },
    async getCustomerPricingTier() {
      await this.customerPricingTier(this.currentCompany.id).then( res => {
        this.isPricingTierEnabled = res[0].isPricingTierEnabled
        this.customerTierData = res[0].customerTierData
        this.data = res[0].customerTierData
        this.sortData()
      })
    },
    sortData() {
      this.data.sort((a,b) => (a.companyName > b.companyName) ? 1 : ((b.companyName > a.companyName) ? -1 : 0))
    },
    searchCustomerHandler() {
      let value = this.searchCustomer
      this.fullScreenLoading = true
      this.data = []
      if( value ) {
        this.customerTierData.map(item => {
          if(item.companyName.toLowerCase().match(value.toLowerCase())) {
            this.data.push(item)
          }
        })
      } else {
        this.data = this.customerTierData
      }
      this.fullScreenLoading = false
    },

    async handleUpdateCustomerPricingTier(row) {
      this.fullScreenLoading = true

      const { id, creditLimit } = row;
      const tierDefId = row.tierId;
      const victoryCompanyCode = row.victoryCompanyCode?.trim()?.toUpperCase() ?? null;

      const companyListData = await getVictoryCompanyCodeListReqNoFilter(row.organizationId);

      const isNewCompanyCode = !companyListData.some(item => item.CustomerNumber === victoryCompanyCode);

      const payload = {
        tierDefId,
        id,
        victoryCompanyCode,
        creditLimit,
        isCreateNewCust: isNewCompanyCode
      };

      if (!isNewCompanyCode) {
        const companyData = companyListData.find(item => item.CustomerNumber === victoryCompanyCode);
        if (companyData) {
          Object.assign(payload, {
            accountSet: companyData.AccountSet,
            billingCycle: companyData.BillingCycle,
            groupCode: companyData.GroupCode,
            terms: companyData.Terms
          });
        }
      } else {
        Object.assign(payload, {
          accountSet: "TDSGD",
          billingCycle: "MTH",
          groupCode: "ADHOC",
          terms: 14,
        });
      }

      // Send the update request
      try {
        await this.updateCustomerPricingTier(payload);
        this.getData();
        this.fullScreenLoading = false
      } catch (error) {
        this.$message.error(error);
        this.getData();
      }
    },


    getVictoryCompanyCodeList(event,cusId){
      if (event && cusId ) {
        getVictoryCompanyCodeListReq(cusId).then((data) => {
        this.victoryCompanyCodeList = data
      })}
    },


  }
}
</script>
<style lang="scss" scoped>
@import "../../systemManager/companyManager/index.scss";

  /deep/.el-select--small {
    width: 100%;
  }

.transfer-to-from {
    display: flex;
    justify-items: center;
    justify-content: left;
  .arrow-icon{
    height: 100%;
    display: block;
    margin: auto 0;
  }
}

</style>
